button {
  border: none;
  background: none;
}
.rfm-marquee-container {
  overflow-x: visible !important;
}
.cs_link {
  position: fixed;
  z-index: 1000000;
  top: 80%;
  width: 60px;
  right: 2%;
}
.text123 {
  color:#d91400 !important;
}
.ant-statistic-content {
  font-size: 16px !important;

}
.ant-statistic-content-value {
  color:#d91400 !important;
  font-size: 16px !important;

}
.regular-head {
  display: flex;
  align-items: center;
  background: linear-gradient(92.61deg, #222222 0%, #242525 100%);
  border-radius: 16px 16px 0 0;
  padding: 5px 0px 5px 0;

  img {
    height: 80px;
  }

 
}
.left-text {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF99;

}
.right-text {
  font-size: 15px;
  font-weight: 700;
  color: #fff;

}
.regular_line {
  border: 0.5px solid #FFFFFF1A;
  margin: 0px 20px;
}

.lang-icon {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.80);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 20px;
    height: 20px;
  }
}

.symbolBtn {
  margin: 15px 24px 0 24px;
  // width: 100%;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 5px;
  line-height: 35px;
  background: #6552FE;
}

.symbolSet {
  background: #6552FE;
}

.symbol {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 24px;

  .symbol_btn {
    flex: 1;
    border: 1px #fff solid;
    border-radius: 5px;
    padding: 5px 0;
    justify-content: center;
    font-weight: 700;
  }

}

.tbtn_wallet_text {
  font-size: 14px;
  font-weight: 500;
  border-radius: 40px;
  background: #FAFBFB;
  color: #26273C;
}

.tbtn_wallet {
  font-size: 14px;
  font-weight: 500;
  border-radius: 40px;
  background: #FAFBFB;
  padding: 4px 6px;
  text-align: center;
  color: #26273C;
}
.tbtn_wallet1 {
  padding: 4px 12px;
}

.top-wrapper {
  background: #6552FE;
  color: #fff;
  padding: 25px 24px 24px;
  position: relative;

  .one-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      // width: 115px;
      justify-content: space-between;
    }

    .connect {
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.80);
      color: #26273C;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
      width: 135px;
      padding: 7px;

      .icon {
        margin-right: 4px;
        vertical-align: -2px;
      }
    }
  }

  .top-bg {
    position: absolute;
    right: 0;
    bottom: -32%;
    height: 200px;
  }

  .content-block {
    padding-top: 30px;
    width: 80%;

    p:nth-child(1) {
      font-size: 20px;
      font-weight: 500;
    }

    p:nth-child(2) {
      font-size: 13px;
      font-weight: 500;
      margin: 6px 0 16px;
    }

    .tbtn {
      font-size: 14px;
      font-weight: 500;
      border-radius: 40px;
      background: #FAFBFB;
      width: 102px;
      padding: 7px 23px;
      text-align: center;
      color: #26273C;
    }
  }
}

.index {
  width: 100%;
  height: 100vh;
  background-color: #070707;
  overflow: auto;

  .h-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px 0;

    .logo {
      width: 140px;
      display: inline-block;
    }
  }

  .big-img {
    width: 88%;
    float: right;
    margin-top: 20px;
  }

  .h-content {
    clear: both;
    color: #FFF;
    padding: 40px 24px 0;

    h1 {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      color: #FFF;
    }

    p:nth-child(2) {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }

    p:nth-child(3) {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-top: 24px;
    }
  }

  .btn {
    display: flex;
    width: 327px;
    padding: 14px 115px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 16px;
    background: #6552FE;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 50px auto 0;
  }
}

.share-wrapper {
  padding: 18px 24px;

  .share-head {
    font-size: 16px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    position: relative;
    line-height: 48px;

    .back {
      width: 48px;
      height: 48px;
      background-color: #121212;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .share-content {
    padding-top: 44px;

    .title {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
    }

    .share-url {
      border-radius: 16px;
      background: #121212;
      color: rgba(255, 255, 255, 0.60);
      font-size: 12px;
      padding: 11px 16px;
      margin: 16px 0 35px;
      position: relative;

      label {
        display: block;
        width: calc(100% - 60px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .btn {
        border-radius: 16px;
        background: #6552FE;
        font-size: 12px;
        color: #fff;
        display: inline-flex;
        padding: 11px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .share-block {
    border-radius: 25px;
    background: #6552FE;
    color: #fff;
    font-size: 12px;
    padding: 20px;
    position: relative;

    .share-pic {
      position: absolute;
      width: 185px;
      right: -24px;
      top: -10px;
    }

    .s-con {
      width: 80%;
    }

    .share-btn {
      width: 120px;
      border-radius: 40px;
      background: #FAFBFB;
      padding: 7px 23px;
      font-size: 14px;
      color: #26273C;
      margin-top: 11px;
      text-align: center;
      font-weight: bold;
    }
  }
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

.pool-box {
  background: #6552FE;
  height: 100vh;
  overflow: auto;

  .ant-collapse-borderless {
    background: #121212 !important;
    margin-top: 16px;
    border-radius: 16px;
    margin-bottom: 24px;
  }

  .ant-collapse-content,
  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    color: #fff;
  }

  .ant-collapse-borderless>.ant-collapse-item {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.10);
  }

  .ant-collapse-borderless>.ant-collapse-item:last-child {
    border-bottom: 0;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
    font-size: 14px;
    font-weight: bold;
  }

  .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    padding: 12px 0;
  }

  .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: 0;
  }

  .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
    opacity: 0.6;
    font-size: 14px;
  }
}

.pool-content {
  border-radius: 32px 32px 0px 0px;
  background: #070707;
  padding: 24px 24px 80px;
  position: relative;
  background-image: url('../../../public/image/Group48095464.png'), url('../../../public/image/Group48095479.png');
  background-repeat: no-repeat, no-repeat;
  background-size: 100%;
  background-position: 0 30px, 0 500px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    // transform: rotate(17deg); /* 控制旋转角度 */
    z-index: -1;
    /* 确保旋转后的内容位于原始元素之前 */
    pointer-events: none;
    /* 防止点击事件被影响到 */
  }

  .sub-title {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.60);
  }

  .p-con {
    border-radius: 16px;
    background: #121212;
    color: #fff;
    padding: 20px;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 24px;

    li {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 4px;
      }

      label {
        color: rgba(255, 255, 255, 0.60);
        font-weight: 400;
      }
    }

    .roll-con {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 16px;
      }
    }

    .top {
      li {
        &:last-child {
          margin-bottom: 16px;
        }
      }
    }

    span {
      font-weight: bold;
    }
  }

  .pic-group {
    overflow: auto;
    margin-top: 16px;

    li {
      float: left;
      width: 32%;
      margin-right: 2%;
      margin-bottom: 12px;
      border-radius: 16px;
      background: #121212;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      box-sizing: border-box;

      &:nth-child(3n) {
        margin-right: 0;
      }

      img {
        display: block;
        width: 80%;
      }
    }
  }
}

.bottomBar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  position: fixed;
  bottom: 0;
  // left: 0;
  width: 100%;
  background: #070707;

  &:before {
    content: '';
    height: 1px;
    background: rgba(255, 255, 255, 0.10);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  li {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
      display: block;
    }
  }
}

.ant-modal-content {
  background-color: transparent;
}

.lang-content {
  border-radius: 16px;
  background: #121212;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 14px;
  padding-bottom: 24px;

  h1 {
    font-weight: bold;
    font-size: 20px;
    padding-top: 24px;
    text-align: center;
    color: #fff;
  }

  p {
    margin-top: 30px;
    text-align: center;
  }

  .lang-box {
    max-height: 320px;
    overflow-y: auto;
  }

  .rewards-head {
    background: linear-gradient(93deg, #222 0%, #242525 100%);
    padding: 18px 16px;
    display: flex;
    align-items: center;
    color: #fff;
    border-radius: 16px 16px 0px 0px;

    img {
      width: 40px;
      height: 40px;
    }

    .rewards-head-rt {
      padding-left: 6px;

      p:nth-child(1) {
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        margin-top: 0;
      }

      p:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.60);
        margin-top: 6px;
        text-align: left;
      }
    }
  }

  .rewards-content {
    padding: 0 24px;

    ul {
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.10);
      margin-top: 24px;

      li {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;

        label {
          color: rgba(255, 255, 255, 0.60);
        }

        span {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .btn {
      display: flex;
      padding: 14px 0;
      justify-content: center;
      align-items: flex-start;
      font-weight: bold;
      font-size: 16px;
      margin: 24px 0;
      border-radius: 16px;
      background: #6552FE;
    }

    .rewards-bot {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 26px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }

  .caption-content {
    font-size: 13px;
    padding: 24px 24px 16px;
  }

  .catption-img {
    padding: 0 24px;

    img {
      border-radius: 8px;
      display: block;
      width: 100%;
    }
  }

  .exchange-content {
    padding: 0 24px;

    .exchange-btn {
      border-radius: 16px;
      background: #6552FE;
      color: #FFF;
      font-weight: bold;
      display: flex;
      padding: 14px 115px;
      justify-content: center;
      align-items: flex-start;
      margin-top: 32px;
    }

    .exchange-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      text-align: left;
    }

    .exchange-list {
      .exchange-item {
        display: inline-flex;
        padding: 11px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        border: 1px solid #6552FE;
        margin-right: 12px;
        color: #6552FE;
        margin-top: 16px;

        &.active {
          background: #6552FE;
          color: #fff;
        }
      }
    }
  }
}

.close-icon {
  background-color: rgba(255, 255, 255, 0.80);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 16px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-group {
  .tab-box {
    display: flex;
    // width:  calc(100% - 56px);
    justify-content: center;
    border-radius: 30px;
    background: #121212;
    height: 56px;

    li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;

      &.active {
        border-radius: 25px;
        background: #6552FE;
        box-shadow: 0px 20px 40px 0px rgba(55, 62, 125, 0.10);
        font-weight: bold;
      }
    }
  }
}

.tab-content {
  border-radius: 32px 32px 0px 0px;
  background: #121212;
  margin-top: 16px;
  padding: 0 24px 32px;

  .tab-btn {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: #6552FE;
    height: 52px;
    margin-top: 24px;
  }

  .exchange-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0 15px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.10);

    &.nobor {
      border: none;
    }
  }

  .exchange-item-left {
    img {
      width: 50px;
      height: 50px;
      vertical-align: -3px;
      float: left;
    }

    .left-con {
      padding-left: 16px;
      float: left;

      p:nth-child(1) {
        font-size: 18px;
      }

      p:nth-child(2) {
        font-size: 14px;
        color: #9395A4;
      }
    }
  }

  .exchange-item-right {
    input {
      width: 120px;
      font-size: 18px;
      font-weight: 500;
      background-color: transparent;
      border: none;
      caret-color: #6552FE;
      outline: none;
      outline-width: 0;
      -webkit-outline: none;
      box-shadow: none;
      border-bottom: 0.5px solid #6552FE;
      color: rgba(255, 255, 255, 0.60);
    }

    span {
      display: inline-block;
      border-radius: 25px;
      background: #6552FE;
      box-shadow: 0px 20px 40px 0px rgba(55, 62, 125, 0.10);
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      padding: 6px 12px;
    }

    label {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.3);
}

.record-tab-group {
  padding-top: 24px;

  .record-tab-box {
    border-radius: 16px;
    background: #222;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      flex: 1;
      color: #fff;
      font-size: 12px;
      height: 40px;
      text-align: center;
      line-height: 40px;

      &.active {
        border-radius: 16px;
        background: #6552FE;
      }
    }
  }

  .record-list-box {
    .record-list-item {
      font-size: 12px;
      color: #fff;
      display: flex;
      justify-content: center;

      &.record-list-title {
        color: rgba(255, 255, 255, 0.60);
        display: flex;
        justify-content: center;
      }

      li {
        flex: 1;
        margin-top: 16px;
        text-align: center;
      }

      li:nth-child(1) {
        text-align: left;
      }

      li:nth-child(4) {
        text-align: right;
        flex: none;
        width: 18%;
      }
    }
  }
}

// 去除默认的半透明背景
.marquee-container .overlay::before,
.marquee-container .overlay::after {
  background: none;
}

.rfm-child,
.rfm-initial-child-container {
  width: 100%;
}

.rfm-marquee {
  margin-right: 36px;
}

// 竖向滚动
.mymarquee .marquee {
  // 默认 flex 布局，根据实际作更改
  display: block !important;
  // 改为竖向
  animation: vscroll var(--duration) linear var(--delay) var (--iteration-count);
}

// 竖向滚动动画
@keyframes vscroll {
  0% {
    transform: translateY(0%)
  }

  100% {
    transform: translateY(-100%)
  }
}